import React, { useEffect, useState } from 'react'
import { Product, useGetAllProductsQuery } from '../../generated/graphql'
import ProductCard from '../ProductCard/ProductCard'
import AliceCarousel from 'react-alice-carousel'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { SliderContainer } from '../../utils/AliceCarouselCustomSlider'
import { Container } from './styles'
import ALICE_CAROUSEL_DEFAULTS from '../../utils/AliceCarouselDefaults'

const HighlightsProductsSlider: React.FC = () => {
    const { data, loading, error } = useGetAllProductsQuery({
        fetchPolicy: 'network-only',
        variables: {
            getAllProductInput: {
                type: '',
            },
        },
    })

    const [itens, setItens] = useState<any[]>()
    const responsive = {
        0: { items: 1 },
        320: { items: 2 },
        325: { items: 2 },
        425: { items: 2 },
        568: { items: 3 },
        768: { items: 4 },
        1024: { items: 6 },
    }

    const renderPrevButton = ({ isDisabled }: any): JSX.Element => {
        return (
            <span style={{ opacity: isDisabled ? '' : 1 }}>
                <MdChevronLeft color="black" size="3rem" />
            </span>
        )
    }

    useEffect(() => {
        if (data?.getAllProducts) {
            setItens(
                data.getAllProducts.filter(
                    (item) =>
                        (item.id === 400 ||
                            item.id === 594 ||
                            item.id === 616 ||
                            item.id === 766 ||
                            item.id === 1049 ||
                            item.id === 1114 ||
                            item.id === 1166 ||
                            item.id === 1408 ||
                            item.id === 1453 ||
                            item.id === 1773 ||
                            item.id === 2171 ||
                            item.id === 2184 ||
                            item.id === 2205 ||
                            item.id === 2407 ||
                            item.id === 2416 ||
                            item.id === 2442 ||
                            item.id === 2444 ||
                            item.id === 2491 ||
                            item.id === 2518 ||
                            item.id === 2523 ||
                            item.id === 2543 ||
                            item.id === 2549 ||
                            item.id === 2565 ||
                            item.id === 2580 ||
                            item.id === 2587 ||
                            item.id === 2610 ||
                            item.id === 2614 ||
                            item.id === 2638 ||
                            item.id === 2640 ||
                            item.id === 2645 ||
                            item.id === 2654 ||
                            item.id === 2655 ||
                            item.id === 2675 ||
                            item.id === 2768 ||
                            item.id === 2871 ||
                            item.id === 2893 ||
                            item.id === 2957 ||
                            item.id === 2964 ||
                            item.id === 2965 ||
                            item.id === 2995 ||
                            item.id === 3000 ||
                            item.id === 3035 ||
                            item.id === 3047 ||
                            item.id === 3050 ||
                            item.id === 3071 ||
                            item.id === 3093 ||
                            item.id === 3101 ||
                            item.id === 3103 ||
                            item.id === 3121 ||
                            item.id === 3139 ||
                            item.id === 3166 ||
                            item.id === 3149 ||
                            item.id === 3152 ||
                            item.id === 3175 ||
                            item.id === 3193 ||
                            item.id === 3199 ||
                            item.id === 3200) &&
                        item.categorieId !== 58 &&
                        item.active &&
                        item.stock &&
                        item.stock > 0
                )
            )
            // const ids = itens && itens.map((item) => item.id)
            // console.log(ids)
        }
    }, [data])

    const renderNextButton = ({ isDisabled }: any): JSX.Element => {
        return (
            <span style={{ opacity: isDisabled ? '' : 1 }}>
                <MdChevronRight color="black" size="3rem" />
            </span>
        )
    }

    if (itens?.length === 0 || loading) {
        return <></>
    }

    return (
        <SliderContainer>
            <Container>
                <div className="home-container">
                    <div className="product-cards-container">
                        <div className="container-title">
                            <h1>Destaques</h1>
                        </div>

                        <AliceCarousel
                            {...ALICE_CAROUSEL_DEFAULTS}
                            renderPrevButton={renderPrevButton}
                            renderNextButton={renderNextButton}
                            items={itens?.map((product: Product) => {
                                return (
                                    <ProductCard
                                        product={product}
                                        key={product?.id}
                                    />
                                )
                            })}
                        />
                    </div>
                </div>
            </Container>
        </SliderContainer>
    )
}

export default HighlightsProductsSlider
